import React, { useEffect, useState } from "react"
import {
   Button,
   Col,
   Container,
   Form,
   Row,
   InputGroup,
   FormControl,
} from "react-bootstrap"
import { fetchAPIData, isLoggedIn } from "../../api/AuthService"
import {
   FETCH_VENDOR,
   VENDOR_RESULT,
   SEARCH_WHERE_USED,
   SELECTED_VENDOR_PREFERENCE,
} from "../../constant/Constants"
import HeaderComponent from "../commonComponents/HeaderComponent"
import "../main.css"
import VendorResults from "./VendorResults"

function VendorInfo(props) {
   const [plants, setPlants] = useState([])
   const [vendorData, setVendorData] = useState([])
   const [site, setSite] = useState("")
   const [loading, setLoading] = useState(true)
   const [errorFlag, setErrorFlag] = useState(0)
   const [active, setActive] = useState(true)
   const [view, setView] = useState("Pending")
   const [searchView, setSearchView] = useState(false)
   const [searchBox, setSearchBox] = useState("")
   const [fromViewMore, setFromViewMore] = useState(false)
   const [gcasError, setGCASError] = useState("")
   const [inactiveVendors, setInactiveVendors] = useState("")
   const User = localStorage.getItem("UserId")
   const employee = localStorage.getItem("employee")

   function handleClick(e, isFromSearch) {
      e.preventDefault()
      var siteInfo = document.getElementById("site")
      var viewInfo = document.getElementById("view")
      var siteInfoVal = siteInfo.options[siteInfo.selectedIndex].value
      setSite(siteInfoVal)
      var viewInfoVal = viewInfo.options[viewInfo.selectedIndex].value
      setGCASError("")
      if (viewInfoVal == "Search") {
         setSearchView(true)
         setFromViewMore(true)
         setView(viewInfoVal)
         if (isFromSearch) {
            if (searchBox !== "") {
               fetchData("click", siteInfoVal, viewInfoVal)
               setFromViewMore(false)
            } else {
               setGCASError("*Please enter GCAS/Name above")
               setErrorFlag("")
            }
         }
      } else {
         setSearchView(false)
         setFromViewMore(false)
         setSearchBox("")
         fetchData("click", siteInfoVal, viewInfoVal)
      }
      return false
   }

   function handleViewMorePart(e) {
      let view = document.getElementById("view")
      view.value = "Search"
      setSearchView(true)
      setSearchBox(e)
      setFromViewMore(true)
      var siteInfo = document.getElementById("site")
      var siteInfoVal = siteInfo.options[siteInfo.selectedIndex].value
      fetchData("click", siteInfoVal, "Search", e)
      setFromViewMore(false)
   }

   function handleSearchBoxChange(e) {
      setSearchBox(e.target.value)
   }

   async function fetchData(calledFrom, site, view, viewMoreData = null) {
      try {
         if (calledFrom === "load") {
            const response =
               employee == "PG"
                  ? await fetchAPIData(`${SELECTED_VENDOR_PREFERENCE}/${User}`)
                  : await fetchAPIData(`${FETCH_VENDOR}`)
            const data = employee === "PG" ? response.data.vendors : response.data
            if (employee === "PG" && response.data.InactiveVendors != "") {
               setInactiveVendors(response.data.InactiveVendors)
            }
            if (data.length) {
               setPlants(data)
               setLoading(true)
               setActive(false)
               const vendorResults = await fetchAPIData(
                  `${VENDOR_RESULT}/Pending?companyName=${encodeURIComponent(data[0].name)}`
               )
               if (vendorResults.data) {
                  if (vendorResults.data.EbpObjectList != null) {
                     setVendorData(vendorResults.data.EbpObjectList)
                     setErrorFlag(0)
                     setLoading(false)
                  } else {
                     setErrorFlag(1)
                     setLoading(false)
                  }
                  setSite(data[0].name)
               } else {
                  setErrorFlag(2)
                  setLoading(false)
               }
            } else if (response.status === 200) {
               setErrorFlag(3)
               setLoading(false)
            } else {
               setErrorFlag(2)
               setLoading(false)
            }
         } else {
            setLoading(true)
            setActive(false)

            const vendorResults =
               view !== "Search"
                  ? await fetchAPIData(`${VENDOR_RESULT}/${view}?companyName=${encodeURIComponent(site)}`)
                  : viewMoreData
                  ? await fetchAPIData(`${SEARCH_WHERE_USED}/${viewMoreData}?plantName=${encodeURIComponent(site)}`)
                  : await fetchAPIData(`${SEARCH_WHERE_USED}/${searchBox}?plantName=${encodeURIComponent(site)}`)

            if (vendorResults.data) {
               if (vendorResults.data.EbpObjectList != null) {
                  setVendorData(vendorResults.data.EbpObjectList)
                  setErrorFlag(0)
                  setLoading(false)
               } else {
                  setErrorFlag(1)
                  setLoading(false)
               }
               setSite(site)
               setView(view)
            } else {
               setErrorFlag(2)
               setLoading(false)
            }
         }
      } catch (error) {
         if (error.response && error.response.status === 408) {
            setErrorFlag(4)
            setLoading(false)
         }
         else{
            setErrorFlag(2)
            setLoading(false)
         }
      }
   }

   useEffect(() => {
      if (!isLoggedIn()) {
         props.history.push("/login")
      } else {
         fetchData("load")
      }
   }, [props.history])

   return (
      <React.Fragment>
         <HeaderComponent />
         <Container fluid>
            {inactiveVendors != "" && (
               <div
                  style={{
                     color: "yellow",
                     fontSize: "14px",
                     textAlign: "center",
                     fontWeight: "bold",
                     font: "Century Gothic",
                     marginTop: "10px",
                  }}
               >
                  {inactiveVendors}
               </div>
            )}
            <Row>
               <Col
                  md={{ span: 6, offset: 3 }}
                  sm={{ span: 6, offset: 3 }}
                  align="center"
               >
                  <table
                     style={{
                        textAlign: "center",
                        width: "100%",
                        marginTop: "5%",
                        marginBottom: "5%",
                     }}
                  >
                     <tbody>
                        <tr>
                           <td
                              style={{ width: "20%", verticalAlign: "middle" }}
                              className="labels"
                           >
                              Site/Vendor number
                           </td>
                           <td style={{ width: "25%" }}>
                              <Form.Control
                                 as="select"
                                 id="site"
                                 className="vendorOptions"
                              >
                                 {plants.map((value, index) => {
                                    return (
                                       <option key={index} value={value.name}>
                                          {value.name}
                                       </option>
                                    )
                                 })}
                              </Form.Control>
                           </td>
                           <td
                              style={{ width: "7%", verticalAlign: "middle" }}
                              className="labels"
                           >
                              View
                           </td>
                           <td style={{ width: "25%" }}>
                              <Form.Control
                                 as="select"
                                 id="view"
                                 className="vendorOptions"
                              >
                                 <option value="Pending">Pending Specifications</option>
                                 <option value="Accepted">Accepted Specifications</option>
                                 <option value="Favorites">Favorites</option>
                                 <option value="Rejected">Rejected Specifications</option>
                                 <option value="Search">Search - Where Used</option>
                              </Form.Control>
                           </td>
                           <td style={{ width: "15%" }} align="center">
                              <Button
                                 style={{ backgroundColor: "#09f" }}
                                 onClick={handleClick}
                                 disabled={active}
                              >
                                 GO
                              </Button>
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </Col>
            </Row>
            {searchView ? (
               <Row>
                  <Col>
                     <div
                        style={{
                           display: "flex",
                           justifyContent: "center",
                           marginBottom: "25px",
                        }}
                     >
                        <div
                           style={{
                              color: "white",
                              margin: "8px 8px 8px 0px",
                           }}
                        >
                           Search :
                        </div>
                        <div
                           style={{
                              verticalAlign: "middle",
                              textAlign: "left",
                              float: "left",
                           }}
                        >
                           <Form onSubmit={(e) => handleClick(e, true)}>
                              <InputGroup>
                                 <FormControl
                                    style={{ height: "35px" }}
                                    id="search"
                                    className={`placeHolderText searchBox shadow`}
                                    type="text"
                                    placeholder="&#61442; What are you Looking For?"
                                    onChange={handleSearchBoxChange}
                                    value={searchBox}
                                 />
                                 <InputGroup.Append style={{ height: "35px" }}>
                                    <Button bsPrefix="searchBtnText_copy" type="submit">
                                       <i className="fa fa-search"></i>
                                    </Button>
                                 </InputGroup.Append>
                              </InputGroup>
                           </Form>
                        </div>
                     </div>
                  </Col>
               </Row>
            ) : (
               ""
            )}
            <Row>
               <Col md="12" style={{ marginTop: "0px" }}>
                  {loading ? (
                     <h2 style={{ textAlign: "center", color: "white" }}>
                        <i
                           className="fa fa-spinner fa-spin"
                           style={{ marginRight: "5px" }}
                        />
                        Loading........
                     </h2>
                  ) : (
                     <VendorResults
                        view={view}
                        results={vendorData}
                        error={errorFlag}
                        handleViewMorePart={handleViewMorePart}
                        fromViewMore={fromViewMore}
                        siteInfo={site}
                     />
                  )}
               </Col>
            </Row>
            {gcasError !== "" && (
               <Row className="labels">
                  <Col md={{ span: 6, offset: 3 }} align="center">
                     {gcasError}
                  </Col>
               </Row>
            )}
         </Container>
      </React.Fragment>
   )
}
export default VendorInfo
